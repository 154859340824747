@charset "utf-8";

/*
* public/asset/css/common.css オーバーライド
*/

// カーソル
.cursor {
  &-auto { cursor: auto; }
  &-default { cursor: default; }
  &-pointer { cursor: pointer; }
}

// snsログインボタン
.sns-login {
  .sns-login-wrapper {
    margin-inline: auto;
    li {
      img {
        width: 47px;
      }
    }
  }
}

// フェス詳細
.page--festivals-detail {
  #main__ {
    overflow: visible;
  }
  main {
    overflow: visible;
    .tab-slide {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 20;
    }
  }
}

// 店舗詳細
.page--shops-detail {
  #main__ {
    overflow: visible;
  }
  main {
    overflow: visible;
    .inner_lr {
      overflow: hidden;
    }
    // タブ
    .tab-wrap {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 20;
    }
  }
}

// レビューモーダル
.review_edit_modal {
  .md-photoupload {
    li {
      &::before {
        pointer-events: none;
      }
      label {
        display: block;
        img {
          display: none;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
        &.photo-on {
          img {
            display: block;
          }
          span {
            &.txt {
              display: none;
            }
          }
        }
      }
    }
  }
}

.item {
  .list_inline {
    &.no-pr {
      li {
        padding: 0;
      }
    }
  }
}
